<template>
  <div class="item content">
 <div>
    <p>Юридический адрес:  141002 Московская обл., г. Мытищи, ул. Колпакова, д.2 корп. 15
      </p>
    <p>Фактический адрес: 141002 Московская обл., г. Мытищи, ул. Колпакова, д.2 корп. 15</p>
   <p>Адрес производства: 108828, г. Москва п. Краснопахорское, вблизи деревни Софьино, квартал 171, участок 34.
   </p>
    <p>ОГРН - 1025003527856; ИНН - 7704185209; КПП - 502901001;</p>
    <p>Телефон: +7 (495) 147-82-05 , +7 (903) 564-57-27</p>
    <p>E-mail: info@tehtelecom.ru</p>
    <p>Генеральный директор: Степанов Александр Викторович</p>
    <p>График работы офиса:</p>
    <p>c понедельника по пятницу с 10.00 до 18.00 часов</p>
    </div>
    <div class="mapc">
   <map-component/>
    </div>
    </div>
<!-- </div>-->
</template>
<script>

import {defineComponent} from "vue";
import MapComponent from "@/components/YaMap.vue";

export default defineComponent({
  components: {MapComponent}
})
</script>

<style scoped>
.content {
  grid-area: content;
  padding: 10px;

  display: grid;
  justify-content: center;
  /*grid-template-columns: 1fr;*/
  /*grid-gap: 10px;*/
  /*margin-left: 20px;*/
  text-shadow: 1px 1px 1px #000000;
  line-height: calc((100vw - 480px) / (1280 - 480) * (28 - 20) + 20px);
  font-size: calc((100vw - 480px) / (1280 - 480) * (18 - 12) + 12px);
  /*font: 900 20px Arial;*/
  border-left: 1px solid rgb(255, 255, 255, .4);
  /*border: 3px solid red;*/
}
p{
  margin: 0px 5px;
  /*border: 3px solid red;*/
}
.mapc{
  width: 100%;
  height: 100%;
  /*border: 3px solid red;*/
  overflow: auto;
}
</style>