<template>
  <div class="item content">
    <div>
      <SidebarBlock2 />
    </div>
    <div class="right-block">
      <div class="text-mng">

        <h4 class="title-mng">ПРОЦЕСС СЕРТИФИКАЦИИ СМК ВКЛЮЧАЕТ:</h4>
        <ul>
         <li>организационный этап;</li>
         <li>двухэтапный аудит первоначальной сертификации СМК;</li>
          <li>инспекционный контроль в течение первого и второго года после выдачи сертификата соответствия;</li>
          <li>ресертификация на третий год до истечения срока действия сертификата соответствия.</li>
        </ul>
        <h4 >1 ОРГАНИЗАЦИОННЫЙ ЭТАП</h4>
      <p>
         1.1	Подача заявки на сертификацию СМК (заявитель)<br>
         1.2	Анализ заявки на предмет возможности проведения работ (орган по сертификации СМК)<br>
         1.3	Заключение договора (орган по сертификации СМК, заявитель)<br>
         1.4	Формирование программы аудита (орган по сертификации СМК)<br>
         1.5	Определение трудоемкости аудита (орган по сертификации СМК)<br>
         1.6	Подготовка и запрос исходной информации (орган по сертификации СМК, заявитель)<br>
         1.7	Формирование экспертной группы (орган по сертификации СМК)<br>
      </p>
         <h4>2 ПЕРВОНАЧАЛЬНАЯ СЕРТИФИКАЦИЯ СМК</h4>

        <h4> 2.1 ПЕРВЫЙ ЭТАП АУДИТА ПЕРВОНАЧАЛЬНОЙ СЕРТИФИКАЦИИ СМК</h4>
        <p>
         2.1.1 Анализ документов СМК заявителя (орган по сертификации СМК)<br>
         2.1.2 Оформление отчета по результатам первого этапа аудита (орган по сертификации СМК)<br>
         2.1.3 Устранение выявленных несоответствий и оформление отчета об устранении несоответствий (заявитель)<br>
         2.1.4 Анализ отчета об устранении несоответствий и оформление заключения о результатах анализа (орган по сертификации СМК)<br>
         2.1.5 Принятие решения о проведении второго этапа аудита первоначальной сертификации СМК (орган по сертификации СМК)<br>
      </p>
        <h4> 2.2 ВТОРОЙ ЭТАП АУДИТА ПЕРВОНАЧАЛЬНОЙ СЕРТИФИКАЦИИ СМК (ПРОВЕДЕНИЕ АУДИТА НА МЕСТАХ)</h4>
        <p>
         2.2.1 Подготовка плана аудита (орган по сертификации СМК)<br>
         2.2.2 Проведение аудита на местах (орган по сертификации СМК, заявитель)<br>
         2.2.3 Подготовка акта по аудиту и отчета по аудиту (орган по сертификации СМК)<br>
         2.2.4 Устранение выявленных несоответствий и оформление отчета об устранении несоответствий (заявитель)<br>
         2.2.5 Анализ отчета об устранении несоответствий и оформление заключения о результатах анализа (орган по сертификации СМК)<br>
         2.2.6 Принятие решения о выдаче сертификата соответствия (орган по сертификации СМК)<br>
         2.2.7 Оформление сертификата соответствия СМК (орган по сертификации СМК)<br>
        </p>
        <h4>3 ИНСПЕКЦИОННЫЙ КОНТРОЛЬ СМК</h4>
          <p>
         3.1 Подача заявки на инспекционный контроль СМК (заявитель)<br>
         3.2 Подготовка плана инспекционного контроля (орган по сертификации СМК)<br>
         3.3 Проведение аудита (инспекционного контроля) на местах (орган по сертификации СМК, заявитель)<br>
         3.4 Подготовка акта по аудиту и отчета по аудиту (орган по сертификации СМК)<br>
         3.5 Устранение выявленных несоответствий и оформление отчета об устранении несоответствий (заявитель)<br>
         3.6 Анализ отчета об устранении несоответствий и оформление заключения о результатах анализа (орган по сертификации СМК)<br>
         3.7 Принятие решения о подтверждении действия (приостановлении действия/ расширении области сертификации/ сужении области сертификации/ отмены действия) сертификата соответствия СМК (орган по сертификации СМК)<br>
          </p>
        <h4>4 РЕСЕРТИФИКАЦИЯ СМК</h4>

        <p>4.1 Порядок ресертификации СМК аналогичен порядку сертификации СМК</p>


        <h4>ПРАВА И ОБЯЗАННОСТИ ЗАЯВИТЕЛЕЙ</h4>

        <em>ЗАЯВИТЕЛЬ ИМЕЕТ ПРАВО:</em>
        <ul>
         <li>на стадии согласования плана аудита отвести кандидатуру любого члена экспертной группы с обоснованием причин;</li>
          <li>на расширение области сертификации (при этом срок окончания действия вновь выдаваемого сертификата соответствия СМК остается тем же, что был указан в предыдущем сертификате соответствия СМК);</li>
          <li>при несогласии с решением об отказе в выдаче сертификата соответствия в течение месяца обратиться в апелляционную комиссию органа по сертификации СМК, а при несогласии с ее решением, в Апелляционный совет СДС «Военный Регистр» в соответствии с ВР РД 01.008;</li>
          <li>на использование знака соответствия;</li>
          <li>добровольно заявить о намерении приостановить/отменить действие сертификата соответствия СМК.</li>
        </ul>
        <h4>ЗАЯВИТЕЛЬ ОБЯЗАН:</h4>
        <ul>
         <li>обеспечивать функционирование сертифицированной СМК в соответствии с требованиями ГОСТ РВ 0015-002-2012;</li>
          <li>обеспечивать условия, необходимые для проведения сертификации, инспекционного контроля, ресертификации СМК и рассмотрения жалоб, включая доступ в подразделения организации, к документации, записям (в том числе о проведении внутренних аудитов СМК) и персоналу, а также представлять достоверные доказательства, подтверждающие выполнение требований ГОСТ РВ 0015-002-2012;</li>
          <li>использовать сертификат соответствия СМК применительно только к той области, которая определена в сертификате соответствия;</li>
          <li>в случае приостановления или отмены действия сертификата соответствия СМК прекращать использование всех рекламных материалов, содержащих ссылки на сертификат соответствия, и возвратить в орган по сертификации СМК сертификат соответствия;</li>
          <li>информировать орган по сертификации СМК обо всех изменениях в своей организации, связанных со структурой, управлением, местоположением, формой собственности, технологиями изготовления, условиями производства, процессами и т.п.;</li>
          <li>осуществлять коррекции и корректирующие действия по результатам сертификации, инспекционного контроля и ресертификации СМК;</li>
          <li>назначать полномочных представителей для решения вопросов, связанных с проведением сертификации, инспекционного контроля и ресертификации СМК;</li>
          <li>в установленные сроки оплачивать все расходы, связанные с сертификацией, инспекционным контролем и ресертификацией СМК;</li>
          <li>не использовать сертификат соответствия СМК и документы по сертификации (акты и отчеты по аудиту и т.д.) таким образом, чтобы это могло дискредитировать орган по сертификации или вводить в заблуждение потребителей;</li>
          <li>не наносить знак соответствия на продукцию, упаковку продукции, отчеты о лабораторных исследованиях, протоколы поверки или испытаний или использовать его иным способом, который может быть истолкован как подтверждение соответствия качества продукции;</li>
          <li>не использовать сертификат соответствия СМК для структурных подразделений, филиалов или видов деятельности организации, не входящих в область сертификации СМК;</li>
          <li>не ссылаться на сертификат соответствия СМК и использовать знак соответствия при приостановлении, отмене или окончании срока действия сертификата соответствия.</li>
        </ul>
        <h4>ПОРЯДОК РАССМОТРЕНИЯ ЖАЛОБ И АПЕЛЛЯЦИЙ</h4>
        <h4>1 Порядок рассмотрения апелляций</h4>
        <p>
         1.1 Апелляции подаются заинтересованной стороной на имя руководителя органа по сертификации СМК.<br>
         1.2 По указанию руководителя органа по сертификации СМК руководитель группы обеспечения работ готовит и отправляет письмо в адрес организации, подавшей апелляцию.<br>
         1.3 После ознакомления, руководитель органа по сертификации СМК передает материалы председателю апелляционной комиссии органа по сертификации СМК (АК).<br>
         Председатель АК организует подготовительную работу в течение 10-и дней с момента получения апелляции и назначает дату заседания комиссии.<br>
         1.4 Подготовительная работа включает:<br>
         –	предварительное ознакомление членов АК с поступившей апелляцией;<br>
         –	проведение (при необходимости) предварительного обсуждения существа апелляций с любой из заинтересованных сторон;<br>
         –	получение от главного эксперта, а при необходимости от всех членов экспертной группы, разъяснений в виде служебных записок на имя председателя АК, по существу апелляции.<br>
         1.5 На заседание АК могут приглашаться в качестве наблюдателей представитель организации, подавшей апелляцию, и главный эксперт, проводивший работу в организации, подавшей апелляцию.<br>
         1.6 АК вырабатывает с учетом мнений экспертов решение по существу апелляции. Решение принимается открытым голосованием простым большинством голосов и оформляется протоколом.<br>
         1.7 Протокол подписывается всеми членами апелляционной комиссии, утверждается руководителем органа по сертификации СМК и передается секретарем АК руководителю группы обеспечения работ для направления выписки из протокола в адрес организации, подавшей апелляцию. Впоследствии протокол передается руководителю группы ведения документации для внесения в архив органа по сертификации СМК. В случае если апелляцию подала организация, на которой проводил работы по сертификации руководитель органа по сертификации СМК, протокол утверждается его заместителем.<br>
         Апелляция (материалы апелляции, поступившие от организации) передается секретарем АК руководителю группы ведения документации для внесения в архив органа по сертификации СМК.<br>
         1.8 Решение АК в рамках органа по сертификации СМК является окончательным. Не согласная с этим решением сторона может обратиться в Апелляционный совет СДС «Военный Регистр».<br>
         По вопросам, затрагивающим финансовые интересы сторон, не согласная с решением АК сторона, имеет право обратиться установленным порядком в суд.<br>
         1.9 Контроль за выполнением принятых в рамках решения АК коррекций и корректирующих действий осуществляет председатель АК и в случае их невыполнения сообщает руководителю органа по сертификации СМК для принятия решения.<br>
        </p>
        <h4>2 Порядок рассмотрения жалоб</h4>
        <p>
         2.1 Жалобы могут поступать в адрес органа по сертификации СМК как непосредственно от заинтересованной стороны, так и через Центральный орган СДС «Военный Регистр».<br>
         2.2 Обезличенные жалобы к рассмотрению не принимаются.<br>
         2.3 Руководитель органа по сертификации СМК проводит предварительный анализ жалобы, в ходе которого определяется, относится ли жалоба к деятельности по сертификации, за которую органа по сертификации СМК несет ответственность, и если это так, передает жалобу (поступившие материалы по жалобе) председателю АК. При этом руководитель группы обеспечения работ готовит и отправляет письмо в адрес организации, предъявившей жалобу, с подтверждением о ее получении.<br>
         В противном случае руководитель группы обеспечения работ, по указанию руководителя органа по сертификации СМК, направляет письмо в адрес организации, предъявившей жалобу, об отклонении жалобы, как не относящейся к деятельности органа по сертификации СМК.<br>
         2.4 АК может не привлекаться к работе, и руководитель органа по сертификации СМК может принять решение об отклонении жалобы самостоятельно, если жалоба фактически содержит утверждения (сведения), ложность или необоснованность которых очевидна и не вызывает сомнений.<br>
         2.5 Председатель АК организует подготовительную работу в течение 5-и дней с момента получения жалобы и назначает дату заседания комиссии.<br>
         Подготовительная работа включает:<br>
         –	направление письма организации, на которую поступила жалоба, с приложением самой жалобы (поступивших материалов по жалобе);<br>
         –	предварительное ознакомление членов АК с поступившей апелляцией;<br>
         –	сбор и проверку всей информации, необходимой для оценки обоснованности жалобы;<br>
         –	проведение (при необходимости) предварительного обсуждения существа апелляций с любой из заинтересованных сторон;<br>
         –	получение от главного эксперта, а при необходимости от всех членов экспертной группы, разъяснений в виде служебных записок на имя председателя АК, по существу жалобы;<br>
         –	рассмотрение результативности сертифицированной системы менеджмента качества, если жалоба имеет отношение к организации, имеющей сертификат органа по сертификации СМК.<br>
         2.6 На заседание АК могут приглашаться в качестве наблюдателей представитель организации, предъявившей жалобу, и главный эксперт, проводивший работу в организации, на которую предъявлена жалоба.<br>
         2.7 АК вырабатывает с учетом мнений экспертов решение по существу жалобы. Решение принимается открытым голосованием простым большинством голосов и оформляется протоколом.<br>
         2.8 Протокол подписывается всеми членами апелляционной комиссии, утверждается руководителем органа по сертификации СМК и передается секретарем АК руководителю группы обеспечения работ для направления выписки из протокола в адрес организации, предъявившей жалобу, и в адрес организации, на которую предъявлена жалоба. Впоследствии протокол передается руководителю группы ведения документации для внесения в архив органа по сертификации СМК. В случае если жалоба предъявлена на организацию, на которой проводил работы по сертификации руководитель органа по сертификации СМК, протокол утверждается его заместителем.<br>
         Жалоба (поступившие материалы по жалобе) передается секретарем АК руководителю группы ведения документации для внесения в архив органа по сертификации СМК.<br>
         2.9 В случае, если жалоба поступила в органа по сертификации СМК через Центральный орган СДС «Военный Регистр», выписка из протокола направляется в его адрес.<br>
         2.10 Решение АК в рамках органа по сертификации СМК является окончательным. Не согласная с этим решением сторона может обратиться в Апелляционный совет СДС «Военный Регистр».<br>
         По вопросам, затрагивающим финансовые интересы сторон, не согласная с решением АК сторона, имеет право обратиться установленным порядком в суд.<br>
         2.11 Контроль за выполнением принятых в рамках решения АК коррекций и корректирующих действий осуществляет председатель АК и в случае их невыполнения сообщает руководителю органа по сертификации СМК для принятия решения.<br>
        </p>

      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlock2 from "@/components/SidebarBlock2.vue";

export default {
  name: "ProcsertPage",
  components: {SidebarBlock2}
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;
  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);

}
.right-block{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid rgb(255,255,255,.4);

}

.title-mng{
  /*padding: 0px 20px;*/
  text-align: center;
}
.text-mng{
  padding: 10px 15px;
  max-width: 1000px;
  /*border: 1px solid red;*/
}
li, p {
  line-height: 1.5;
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;
  }
}
</style>