<template>
  <div class="item content">
    <div>
      <SidebarBlock2 />
    </div>
    <div class="right-block">
      <div class="text-mng">

        <h4 class="title-mng">ПЕРЕЧЕНЬ ОСНОВНЫХ НОРМАТИВНЫХ ДОКУМЕНТОВ:</h4>

        <h4 class="title-mng">ПЕРЕЧЕНЬ<br>
          <b>внешних нормативных документов, используемых при осуществлении деятельности органа по сертификации СМК</b>
        </h4>

        <strong>ГОСТ Р ИСО/МЭК 17021-1-2017</strong> Оценка соответствия. Требования к органам, проводящим аудит и сертификацию систем менеджмента<br><br>

        <strong>ГОСТ Р ИСО 9001-2015</strong> Системы менеджмента качества. Требования<br><br>

        <strong>ГОСТ Р ИСО 19011-2021</strong> Руководящие указания по аудиту систем менеджмента<br><br>

        <strong>ГОСТ Р 58876-2020</strong> Системы менеджмента качества организаций авиационной, космической и оборонной отраслей промышленности. Требования<br><br>

        <strong>ГОСТ РВ 0015-002-2020</strong> Система разработки и постановки на производство военной техники. Системы менеджмента качества. Требования<br><br>

        <strong>ГОСТ РВ 0015-002-2012</strong> Система разработки и постановки на производство военной техники. Системы менеджмента качества. Общие требования<br><br>

        <strong>ГОСТ РВ 0015-003-2017</strong> Система разработки и постановки на производство военной техники. Порядок проверки систем менеджмента качества организаций, поставляющих военную продукцию<br><br>

        <strong>ВР РД 01.001-2018</strong> Правила функционирования Системы добровольной сертификации "Военный Регистр"<br><br>

        <strong>ВР РД 02.001-2018</strong> Система добровольной сертификации "Военный Регистр".<br> Положение об органе по сертификации систем менеджмента<br><br>

        <strong>ВР РД 02.006-2017</strong> Система добровольной сертификации "Военный Регистр".<br>
        Положение об экспертах<br><br>

        <strong>ВР РД 03.001-2018</strong> Система добровольной сертификации "Военный Регистр". <br>Порядок проведения работ по сертификации систем менеджмента качества<br><br>

        <h4 class="title-mng">ПЕРЕЧЕНЬ<br>
          <b>внутренних документов (процедур) системы менеджмента качества органа по сертификации СМК</b>
        </h4>
        <table>
        <tr>
          <th>
            Идентификационный №
          </th>
          <th>
            Наименование внутреннего документа
            (процедуры) системы менеджмента качества
          </th>
        </tr>
          <tr>
            <th colspan="2"><i>Общесистемные документы органа по сертификации СМК</i></th>
          </tr>
          <tr>
            <td></td>
            <td>
              Политика органа по сертификации СМК в области качества
            </td>
          </tr>
          <tr>
            <td>
              ПЛ ВР-ОССМК-01-2020
            </td>
            <td>
              Положение об органе по сертификации систем менеджмента качества АО НТЦ «Техтелеком-АС»
            </td>
          </tr>
          <tr>
            <td>
              РК ВР-ОССМК-02-2020
            </td>
            <td>
              Руководство по качеству органа по сертификации СМК АО НТЦ «Техтелеком-АС»
            </td>
          </tr>
          <tr>
            <td>
              ПЛ ВР-ОССМК-03-2019
            </td>
            <td>
              Положение о Комитете по беспристрастности
            </td>
          </tr>
          <tr>
            <td>
              ПЛ ВР-ОССМК-04-2019
            </td>
            <td>
              Положение об Апелляционной комиссии
            </td>
          </tr>
          <tr>
          <th colspan="2"><i>Документированные процедуры, регламентирующие порядок выполнения работ</i></th>
          </tr>
          <tr>
            <td>
              ИН ВР-ОССМК-05-2020
            </td>
            <td>
              Инструкция «Порядок проведения работ по сертификации систем менеджмента качества»
            </td>
          </tr>
          <tr>
            <td>
              ИН ВР-ОССМК-07-2019
            </td>
            <td>
              Инструкция «Управление документацией и записями»
            </td>
          </tr>
          <tr>
            <td>
              ИН ВР-ОССМК-08-2019
            </td>
            <td>
              Инструкция «Порядок подготовки, мониторинга и повышения квалификации персонала»
            </td>
          </tr>
          <tr>
            <th colspan="2"><i>Должностные инструкции персонала органа по сертификации СМК</i></th>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-09-2019
            </td>
            <td>
              Должностная инструкция руководителя органа по сертификации
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-10-2019
            </td>
            <td>
              Должностная инструкция заместителя руководителя органа по сертификации
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-11-2019
            </td>
            <td>
              Должностная инструкция руководителя группы ведения документации
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-12-2019
            </td>
            <td>
              Должностная инструкция руководителя группы обеспечения работ
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-13-2019
            </td>
            <td>
              Должностная инструкция главного эксперта
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-14-2019
            </td>
            <td>
              Должностная инструкция эксперта
            </td>
          </tr>
          <tr>
            <td>
              ДИ ВР-ОССМК-15-2019
            </td>
            <td>
              Должностная инструкция технического эксперта
            </td>
          </tr>
        </table>
      </div>
    </div>
    </div>

</template>

<script>
import SidebarBlock2 from "@/components/SidebarBlock2.vue";

export default {
  name: "DocPage",
  components: {SidebarBlock2}
}
</script>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 200px 1fr;
  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);

}
.right-block{
  display: flex;
  /*flex-flow: column nowrap;*/
  justify-content: center;
  align-items: flex-start;
  border-left: 1px solid rgb(255,255,255,.4);

}

.title-mng{
  /*padding: 0px 20px;*/
  text-align: center;
}
.text-mng{
  padding: 10px 15px;
  max-width: 1000px;
  /*border: 1px solid red;*/
}
li, p {
  line-height: 1.5;
}
table, th, td {
  border: 1px solid white;
  /*border-collapse: collapse;*/
}
td{
  padding: 0 3px 0 10px;
}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;
  }
}
</style>

