<template>
  <div class="item content" v-bind:style='{"grid-template-columns": (side1 || side2? "minmax(0px,200px) 1fr" : "1fr" )}'>
    <div class="left-block">
    <Transition>
       <SidebarBlock  v-if="side1" />
    </Transition>
      <Transition>
<!--      <SidebarBlock2 v-if="side2 === 'true'" />-->
        <SidebarBlock2 v-if="side2"  />
        </Transition>
    </div>
    <div class="right-block">
<!--           <p> {{$route.params.side2}}</p>-->
<!--      <p>{{side3}}</p>-->
    <h3 class="title-mng">Орган по сертификации систем менеджмента качества
      АО НТЦ «ТЕХТЕЛЕКОМ-АС»</h3>
<div class="certreg">
  <div class="voenreg" >
  <img class="regicon" @click="$router.push('/certmng/t/f')" src="@/assets/img/voenreg2.png" />

<!--    <h4 class="regbtn" @click="side3 = !side3">Сертификация в СДС <br>"Военный Регистр"-->
    <h4 class="regbtn" @click="$router.push('/certmng/t/f');isActive = !isActive">Сертификация в СДС <br>"Военный Регистр"
<!--      <div class="voenreg-menu" v-bind:style='{"left": (isActive? "-100%" : "0" )}'>-->
<!--        <button class="btn btn1" @click="$router.push('/ak')">Аттестат аккредитации</button>-->
<!--        <button class="btn btn2" @click="$router.push('/obl')">Область аккредитации</button>-->
<!--        <button class="btn btn3" @click="$router.push('/certmng/f/t')">Порядок проведения работ</button>-->
<!--        <button class="btn btn4" @click="$router.push('/form');foc" ref="myInput">Формы заявок</button>-->
<!--    </div>-->
  </h4>

  </div>
  <div  class="oboronreg">
  <img src="@/assets/img/oboronreg.gif" />
  <h4>Сертификация в СДС <br>"Оборонный Регистр"</h4>
   </div>
</div>

    <h4 class="title-mng">Руководитель органа по сертификации – Степанов Александр Викторович – 8-903-564-57-27.</h4>

    <div class="text-mng">
<!--<ul>-->
      <p>В декабре 2021 года исполнится 4 года со дня образования органа по сертификации систем менеджмента качества (далее – орган по сертификации) Акционерного общества НТЦ «Техтелеком-АС». Орган по сертификации является преемником органа по сертификации Закрытого акционерного общества «Каскад-Телеком», который в свою очередь был создан на базе образованного в 1998 году органа по сертификации систем качества «Воентест» 32 Государственного научно-исследовательского испытательного института Министерства обороны Российской Федерации (32 ГНИИИ МО РФ).</p>
      <p>Несмотря на то, что орган по сертификации два раза менял свое наименование, а точнее юридическое лицо, практически неизменным остался как состав руководства органа по сертификации, так и состав экспертов, высокий профессионализм и компетентность которых формируют авторитет и заслуженное уважение к органу по сертификации. Подтверждением этому является наличие в составе экспертов докторов и кандидатов технических наук.
      В активе экспертов органа по сертификации многочисленные публикации по проблемным вопросам разработки и сертификации систем менеджмента качества, метрологического обеспечения в ведущих российских научно-технических изданиях, в том числе, «Стандарты и качество», «Методы менеджмента качества», «Методы оценки соответствия», «Измерительная техника», «Законодательная и прикладная метрология», выступления на международных и всероссийских научно-технических конференциях.</p>
<!-- </ul>-->
<!--      <ul>К заслугам экспертов органа по сертификации необходимо также отнести:-->
      <p>
      — проведение с 1994 года комплексных научно-исследовательских работ по созданию организационных и методических основ деятельности по сертификации продукции и систем качества, а также обоснованию экономической целесообразности такой деятельности применительно к Министерству обороны Российской Федерации и оборонно-промышленному комплексу;</p>
      <p>— непосредственное участие в создании в 2000 году СДС «Военный Регистр» и в 2002 году СДС «Оборонный Регистр» и разработке их руководящих документов;</p>
      <p> —  участие в разработке Федерального закона «Об обеспечении единства измерений» (№ 102-ФЗ от 26 июня 2008 года), ГОСТ РВ 15.002-2003 (подраздел 7.6 — в полном объеме), ГОСТ РВ 0015-002-2012, ГОСТ РВ 0015-002-2020 (подраздел 7.1.5 – в полном объеме) и целого ряда стандартов государственной системы обеспечения единства измерений, в том числе, ГОСТ Р 8.568-97, ГОСТ РВ 8.570-98, ГОСТ РВ 8.572-99, ГОСТ РВ 8.575-2000, ГОСТ РВ 0008-001-2013 и др.</p>
      <p>Сегодня орган по сертификации уверенно чувствует себя на рынке добровольных услуг по сертификации систем менеджмента качества, являясь одним из лидеров в СДС «Военный Регистр» и СДС «Оборонный Регистр» по количеству Заказчиков.</p>
<!--      </ul>-->
      <h4>Орган по сертификации АО НТЦ «Техтелеком-АС» отличают: </h4>
<ul>
      <li> широкая область аккредитации;</li>
      <li>высококвалифицированные эксперты с 20-летним опытом работы в области сертификации систем менеджмента качества;</li>
      <li>реестр из более чем 100 предприятий и организаций, в том числе оборонно-промышленного комплекса;</li>
      <li>индивидуальный подход к Заказчикам и умеренные цены за услуги в области сертификационной деятельности (скидки постоянным клиентам);</li>
      <li>информационная, методическая и техническая поддержка в вопросах, связанных с сертификацией систем менеджмента качества и метрологическим обеспечением разработки, производства и ремонта вооружения и военной техники (семинары и консультации специалистов), а также обучение персонала предприятий.</li>
</ul>
    </div>
  </div>
    </div>

</template>

<script setup>
import {computed} from 'vue'
import {useRoute} from 'vue-router'
const route = useRoute()
const side1 = computed(() => Boolean(route.params.side1 === 't'))
const side2 = computed(() => Boolean(route.params.side2 === 't'))
</script>-->

<script>
import SidebarBlock from "@/components/SidebarBlock.vue";
import SidebarBlock2 from "@/components/SidebarBlock2.vue";
// import {useRoute} from 'vue-router';

export default {
  name: "CertmngPage",
  components: {SidebarBlock2, SidebarBlock},
  data (){
    return {
      isActive: true,

      // isActive1: false,

      // isActive2: false

    }
  },
  // route: useRoute(),
  // computed: { return(side1) => Boolean(this.route.params.side1 === 'true')}

  // methods:
  //     {console.log(this.$route.params)}
}
</script>

<style scoped>
.content {
  grid-area: content;

  display: grid;

  /*grid-template-columns: 1fr 1fr;*/
  /*grid-template-columns: minmax(0px,200px) 1fr;*/
  /*display: flex;*/
  /*flex-flow: column nowrap;*/
  /*justify-content: center;*/
  /*align-items: center;*/

  word-wrap: break-word;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
  /*padding: 10px;*/
  position: relative;
}
.left-block{
  /*border: solid 1px greenyellow;*/
  /*display: none;*/
  /*transition: 2s;*/
}
.right-block{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /*border: solid 1px red;*/
  border-left: 1px solid rgb(255,255,255,.4);
}
.title-mng{
  padding: 0px 20px;
  text-align: center;
}
.text-mng{
  padding: 10px 15px;
max-width: 1000px;
  /*border: 1px solid red;*/
}
li, p {
  line-height: 1.5;
}
.certreg{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  /*border: 1px solid red;*/
}

.voenreg,.oboronreg{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  color: white;
  text-align: center;
}
.regbtn:hover,.regicon:hover{
  cursor: pointer;
  color: wheat;
}
/*.regbtn:hover .voenreg-menu{*/
/*  left: 0  !important;*/
/*  transition: 1.2s;*/
/*  opacity: 0.8;*/
/*}*/
img {
  max-width: 30%;
  height: auto;
  /*border: 1px solid red;*/
  /*object-fit: cover;*/
  /*transform: scale(0.3, 0.3);*/
}
.voenreg-menu{
display: none;
}
.btn{
  text-shadow: 0px 1px 0px #000000;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: .7em 1em calc(.8em + 3px);
  border-radius: 3px;
  background-color: rgb(01, 127, 212);
  border-color: rgb(255,255,255,.5);

  /*background: rgb(64,199,129);*/
  /*background: rgb(24, 84, 133);*/
  /*background: #0174c2;*/
  box-shadow: 0 -1px rgb(255,255,255,.1) inset;
  transition: 0.2s;
}
.btn:hover {
  /*background-color: rgb(195, 212, 100);*/
  background: #68BDFDFF;
  /*background: rgba(255,255,255,.2);*/
  cursor: pointer;}
.btn:active { background: white; }

.v-enter-active
{
  transition: opacity 1.5s ease;
}
/**/
.v-enter-from
 {
  opacity: 0;
}
.nav-item.ltd-cert {border-bottom: solid 2px  wheat;}

@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr !important;

  }

  /*.left-block {*/
  /*  display: none;*/
  /*}*/
  /*.voenreg-menu{*/
  /*  position: absolute;*/
  /*  top:0px;*/
  /*  left:-100%;*/
    /*display: flex;*/
    /*flex-flow: column nowrap;*/
    /*width: 200px;*/
    /*transition: 0.8s;*/
  /*}*/
}

</style>