<template>
  <div class="item content">
<!--    <ul>-->
    <div class="about">
    <p>Акционерное общество НТЦ «Технологии телекоммуникаций и автоматизированных систем» (АО НТЦ «Техтелеком-АС») образовано в 1998 году.</p>
    <p>Деятельность АО НТЦ «Техтелеком-АС» основана на соблюдении установленных законодательством России и международными организациями норм и правил и наличии необходимых разрешительных документов (лицензий, аттестатов аккредитации и других документов).</p>
<!--    </ul>-->
    </div>
  </div>
</template>

<style scoped>
.content {
  grid-area: content;
  display: flex;
  justify-content: center;

  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
  /*font: 900 20px Arial;*/
  /*border-left: 1px solid rgb(255,255,255,.4);*/
  /*max-width: 900px;*/
  /*border: 1px solid red;*/
}
.about{
padding: 40px 40px;
  max-width: 1000px;
  /*border: 1px solid greenyellow;*/
}
p{
  /*max-width: 900px;*/
  padding: 5px 10px;
  margin: 0;
}
</style>






